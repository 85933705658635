var initPage =  (function () {

	// VARIABLE
	var input = $('.form-group input');
	var contactButton = $('.contact-us, .close-icon');
	var contactSection = $('.contact-slide');
	var sentAgain = $('#send-again');
	var form = $('#formContact');
	var actionMap = $('.slide-map-arrow');
	var slideMap = $('.row.row-map.slide:not(.row.row-map.img), .row.row-map.over-map:not(.row.row-map.img)');
	var svg = $('.map-module .circle svg');
	var arrowsDown = $(".hero-down, .live-down, .live-2-down, .to-top, .logo-nav");

	// METODOS
	var showForm = function() {
		if ($(contactSection).hasClass('show-form')){
			$(contactSection).removeClass('show-form');
			cleanForm();
		}
		else {
			$(contactSection).addClass('show-form');
		}
	};

	var cleanForm  = function() {
		form.find("input, textarea").not('.wpcf7-submit').val("");
		form.find('label.error').html(" ");
		$('.form-group').removeClass('active');
	};

	var formAction = function(back) {
		if (!back) {
			$('.contact-slide').fadeOut(1500, function() {
				$('.sent').fadeIn();
				$('.sent').addClass("thankyou");
				cleanForm();
			});
		} else {
			$('.contact-slide').fadeIn(1500, function() {
				$('.sent').fadeOut();
			});
		}
	};

	var scrollDown = function(from) {
		if (from.hasClass('hero-down')) {
			$.scrollTo($('.module-1'), 800);
		};

		if (from.hasClass('live-down')) {
			$.scrollTo($('.map-module'), 800);
		};

		if (from.hasClass('live-2-down')) {
			$.scrollTo($('.module-3'), 800);
		};

		if (from.hasClass('to-top')) {
			$.scrollTo($('#top'), 800);
		};

		if (from.hasClass('logo-nav')) {
			$.scrollTo($('#top'), 800);
		};

	};

	var sendForm = function() {
		$.ajax({
			type: 'POST',
			url: 'sendform.php',
			data: form.serialize(),
			success: function (data) { 
				formAction(false);
			},
			error: function (error) {
          	}
		});
	};

	var changeArrow = function() {
		if (svg.hasClass('arrow')) {
			svg.addClass('right-arrow').removeClass('arrow');
		} else {
			svg.removeClass('right-arrow').addClass('arrow');
		};
	};

	var animateMapSlide = function() {
		if (slideMap.hasClass('hide')) {
			slideMap.removeClass('hide');
			$('.map-module .arrow-animate span').text('ver mapa');
			$('#map-wrapper').removeClass('opened-map');
			changeArrow();
			window.setTimeout(function(){
				$('.hide article h1, .hide article section').removeClass('hide-content');
			},600);
		} else {
			slideMap.addClass('hide');
			$('#map-wrapper').addClass('opened-map');
			$('.map-module .arrow-animate span').text('ocultar mapa');
			changeArrow();
			window.setTimeout(function(){
				$('.hide article h1, .hide article section').addClass('hide-content');
			},600);
		};
	};

	$(document).ready(function () {
		//EVENTOS
		input.focusin(function() {
			$(this).parent().addClass('focus');
		});

		input.focusout(function() {
			$(this).parent().removeClass('focus');
		});

		input.keyup(function(event) {
			if ($(this).val() !== '') {
				$(this).parent().addClass('active');
			} else {
				$(this).parent().removeClass('active');
			};
		});
		contactButton.on('click', function(){
			showForm();
		});
		sentAgain.on('click', function(){
			formAction(true);
			$('.sent').removeClass("thankyou");
		});
		form.validate({
			rules: {
				name: 'required',
				tel: {
					required: true,
	  				number: true
				},
				email: {
					required: true,
					email: true
				},
				comment: {
					required: true,
				}
			},
			messages: {
				name: "Ingrese un nombre",
				email: "Ingrese una direccion de correo.",
				tel: "Ingrese un numero de telefono.",
				comment: "Ingrese un Comentario"
			}
		});
		form.submit(function (event) {
			event.preventDefault();
			if (form.valid()) {
				sendForm();
			};
		});
		actionMap.on('click', function(){
			animateMapSlide();
		});
		arrowsDown.on('click', function() {
			scrollDown($(this));
		});
		if ( $(window).width() <= 500 ) {
		
			$('.grid-module .grid-quarter').slick({
				arrows: false,
				dots: true,
				fade: true,
			});
		};

		$(window).scroll(function() {
			var nav_fix = $('.progressive-nav').offset().top - $(document).scrollTop();
			var hero = $('.hero').height() - 70;
			var page_pos = $(document).scrollTop(); 

			if ((nav_fix <= 0)) {
		  		$('.progressive-nav').addClass('fixed');
		  		$('.hero .contact-us').attr( 'style', 'top: 20px');
			}

			if ((page_pos <= hero)) {
				$('.progressive-nav').removeClass('fixed');
				$('.hero .contact-us').attr( 'style', 'top: 40px');
			}

		});

		// PROGRESSIVE NAV		
		if (typeof InstallTrigger !== 'undefined') {
			browserName = "Firefox";
			var $document = $('html, body');
		}
		else {
			var $document = $( document.body );
		}
		var $revealBar = $( '.red-container' );
		var wrapperHeight = $document.height();
		var stepDistance = 101;
		var documentHeight = window.innerHeight;
		var positions = [];
		var heights = [];
		var elements = $( '.item:not(".main")' );

		// Cache heights and positions
		for( var i = 0; i < elements.length; i++ ) {
			var $element = $(elements[i]);
			var height = $element.offset().top + documentHeight;
			if (height > wrapperHeight) {
				height = wrapperHeight;
			}
			positions.push(height);
			heights.push($element.height());
		}

		var $nodes =  $( '.node' );

		// Should totally be debounced /w animation frame. I know,
		// this whole thing is slightly inefficient.
		// And magic numbers too!
		$( document ).scroll( function() {

			nodeTop = $document.scrollTop() + documentHeight + 1;
			var current = 0;

			// Active/non active states
			for( var i = 0; i < positions.length; i++ ) {
				// console.log(nodeTop);
				if((nodeTop + 200) > positions[i]) {
					current = i;
					$nodes.eq( i ).addClass( 'active' );
				} else {
					$nodes.eq( i ).removeClass( 'active' );
				}
			}

			// Bar positioning, so sections can have different heights.
			var distanceToNext = 0;

			// Initial node, already has some of the bar filled.
			if( nodeTop < positions[0] ) {	
				var nextStep = (nodeTop - positions[current]) / (positions[current + 1] - positions[current]);

				// Calculating for node widths.
				var totalWidth = ((stepDistance) + ( current * stepDistance ) + (nextStep * stepDistance)) * 0.4;
				$revealBar.width( (0.6 * stepDistance) + totalWidth );

			// Final node covered.
			
			} else if (nodeTop > positions[positions.length - 1]) {
				$revealBar.width(stepDistance * (current + 1));

			// regular nodes
			} else if ( current < elements.length ) {
				var nextStep = (nodeTop - positions[current]) / (positions[current + 1] - positions[current]);
				var totalWidth = stepDistance + ( current * stepDistance ) + (nextStep * stepDistance);
				$revealBar.width( totalWidth );
			}


			
		})

		// Clicking the nodes... again, nothing special ;)
		var stickyHeight = 70;
		$nodes.each( function( index ) {
			var $node = $( this );
			$node.click( function() {
				if ($(window).width() < 1025) {
					documentHeight = documentHeight + 10;
				}
				console.log(documentHeight);
				// var distance = $node.hasClass('active') ? 10 : 70;
				$('html, body').animate({ scrollTop: (positions[ index ] - documentHeight - stickyHeight)}, 1000 );
			});
		})

	});
});